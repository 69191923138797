import { Sub } from "~/types/subscriptions";

import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
	const objectStorage = __subscription().get<SubObjectStorage>(Sub.Type.ObjectStorage);
	if (!objectStorage || objectStorage.spaceTotal === 0) {
		log("❌ Has no S3 quota");
		return navigateTo("/");
	}

	log("✅ Has S3 quota");
});
